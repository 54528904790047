@import "../../../../assets/theme/_var.scss";

.header {
  &.navbar {
    padding: 2.45rem 0;
    position: sticky;
    top: 0;
    z-index: 111;
    transition: padding linear 0.25s;

    @media (max-width: 1679px) {
      padding: 2rem 0;
    }
    @media (max-width: 991px) {
      padding: 1rem 0;
    }
    &.scrolled {
      padding: 1.5rem 0;
      backdrop-filter: blur(12px);
      // box-shadow: 0 1rem 1.4rem rgba(255, 255, 255, 0.3);
      @media (max-width: 1679px) {
        padding: 1rem 0;
      }
    }

    .navbar-brand {
      padding: 0;
      max-width: 17.5rem;
      @media (max-width: 1679px) {
        max-width: 15rem;
      }
      @media (max-width: 991px) {
        max-width: 12rem;
      }
      @media (max-width: 575px) {
        max-width: 10rem;
      }
    }

    .navbar-nav {
      padding-right: 19rem;

      @media (max-width: 1365px) {
        padding-right: 10rem;
      }

      @media (max-width: 767px) {
        padding-right: 0;
      }
      .nav-link {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: 12.5rem;
        padding: 1.7rem 2.4rem;
        font-size: 1.6rem;
        line-height: 1;
        letter-spacing: 0.48px;
        font-weight: 500;
        text-transform: uppercase;
        color: $baseColor3;
        border-radius: 2.5rem;
        background: $white3;
        transition: all linear 0.25s;
        @media (max-width: 1679px) {
          padding: 1.4rem 2rem;
          font-size: 1.4rem;
        }
        @media (max-width: 991px) {
          padding: 1rem 1.7rem;
          font-size: 1.2rem;
          min-width: 9rem;
        }

        @media (max-width: 575px) {
          // padding: 0.8rem 1.3rem;
          // font-size: 1rem;
          line-height: 0.8;
          min-width: 7rem;
        }

        &:hover {
          box-shadow: 0px -3px 30px 0px rgba(0, 0, 0, 0.1);
        }

        &:last-child {
          margin-left: 2.5rem;
          color: $white;
          background: $orange;
          @media (max-width: 1679px) {
            margin-left: 1.5rem;
          }
          @media (max-width: 575px) {
            margin-left: 0.7rem;
          }
        }
      }
    }
  }
}
