@import "../../../../../assets/theme/_var.scss";

.accordian-sec {
  padding: 4.2rem 0 15rem;

  // @media (max-width: 1679px) {
  //   padding: 3rem 0 18.5rem;
  // }
  @media (max-width: 1679px) {
    padding: 3rem 0 15rem;
  }

  @media (max-width: 575px) {
    padding: 2rem 0 10rem;
  }

  h2 {
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 3.7rem;

    @media (max-width: 1679px) {
      margin-bottom: 2.5rem;
    }

    @media (max-width: 575px) {
      margin-bottom: 1.5rem;
    }
  }

  .accordion {
    max-width: 89.2rem;
    margin: 0 auto;
    counter-reset: section;
    padding-top: 10rem;

    &-item {
      position: relative;
      border-radius: 1.2rem 1.2rem 0 0;
      background-color: $white;
      border: 0;
      box-shadow: 0px -3px 30px 0px rgba(0, 0, 0, 0.05);
      overflow: hidden;
      background-image: url("../../../../../assets/images/acc_btm_bg.png");
      background-repeat: no-repeat;
      background-position: bottom left;
      counter-increment: section;
      margin-bottom: -1rem;

      &:first-of-type {
        border-top-left-radius: 1.2rem;
        border-top-right-radius: 1.2rem;

        .accordion-button {
          border-top-left-radius: 1.2rem;
          border-top-right-radius: 1.2rem;
        }
      }

      &:last-of-type {
        border-bottom-right-radius: 1.2rem;
        border-bottom-left-radius: 1.2rem;
      }

      .count_text {
        top: -1.8rem;
        right: -4.2rem;
        position: absolute;
        counter-increment: section;
        color: rgba(97, 97, 97, 0.1);
        font-size: 17.9rem;
        line-height: 0.7;
        font-weight: 700;
        text-transform: capitalize;

        @media (max-width: 1679px) {
          font-size: 15rem;
        }

        @media (max-width: 991px) {
          font-size: 12rem;
          top: -1rem;
          right: -3rem;
        }

        @media (max-width: 575px) {
          font-size: 7rem;
          top: -0.5rem;
          right: -2rem;
        }
      }

      &:first-of-type {
        .accordion-button {
          border-top-left-radius: 1.2rem;
          border-top-right-radius: 1.2rem;
        }
      }

      &:not(:last-child) {
        .accordion-body {
          padding-bottom: 5rem;
        }
      }
    }

    > div {
      @for $i from 0 through 5 {
        &:nth-child(#{$i}n) {
          margin-top: calc(($i + 1) * (-0rem));
          z-index: $i;
        }
      }
    }

    &-header {
      background-color: $white;
      margin-bottom: 0;
      border-radius: 1.2rem;
    }

    &-button {
      pointer-events: none;
      // background-color: $white;
      // z-index: 2;
      padding: 3rem 3.6rem;
      // font-size: 3rem;
      // font-weight: 400;
      // letter-spacing: 0.9px;
      // text-transform: capitalize;
      // box-shadow: none;
      // color: rgba(97, 97, 97, 0.5);
      line-height: 1;
      transition: all 0.2s ease-in-out;
      // font-size: 2.6rem;

      @media (max-width: 991px) {
        padding: 2rem;
        // font-size: 2rem;
      }

      @media (max-width: 575px) {
        padding: 1.2rem;
        font-size: 1.6rem;
      }

      &:focus {
        box-shadow: none;
      }

      &:not(.collapsed) {
        background-color: $white;
        z-index: 0;
        box-shadow: none;
        color: #0b0b0b;
        font-size: 4.6rem;
        font-weight: 600;
        letter-spacing: 1.38px;
        padding-bottom: 2.5rem;

        @media (max-width: 1199px) {
          font-size: 3rem;
        }

        @media (max-width: 767px) {
          font-size: 2rem;
        }
      }

      &::after {
        content: none;
      }
    }

    .accordion-collapse {
      display: block;
      // height: 0;
    }

    &-body {
      padding: 1rem 3.6rem 3.6rem;

      @media (max-width: 1679px) {
        padding: 1rem 3rem 3rem;
      }

      @media (max-width: 991px) {
        padding: 1rem 2rem 2rem;
      }

      @media (max-width: 575px) {
        padding: 1rem 1.2rem 1.2rem;
      }

      ul {
        li {
          &:not(:last-child) {
            margin-bottom: 1rem;

            @media (max-width: 1679px) {
              margin-bottom: 0.7rem;
            }
          }
        }
      }

      // h5 {
      //   font-weight: 600;
      //   margin-bottom: 1.5rem;
      //   @media (max-width: 1679px) {
      //     margin-bottom: 1rem;
      //   }
      //   @media (max-width: 575px) {
      //     margin-bottom: 0.5rem;
      //   }
      // }

      // h6 {
      //   font-weight: 500;
      // }
      // h6,
      // p {
      //   color: #4f4f4f;
      // }

      p {
        font-weight: 400;
        line-height: 3.2rem;

        @media (max-width: 1679px) {
          line-height: 2.8rem;
        }

        @media (max-width: 991px) {
          line-height: 2.2rem;
        }

        @media (max-width: 575px) {
          font-size: 1.2rem;
          line-height: 1.8rem;
        }
      }
    }
  }
}

.content-wrap {
  display: flex;
  // margin-bottom: 1rem;

  &-icon {
    flex-shrink: 0;
    margin-right: 3rem;

    svg {
      height: auto;
    }

    @media (max-width: 1679px) {
      margin-right: 2.5rem;
      max-width: 14.5rem;
    }

    @media (max-width: 991px) {
      margin-right: 2rem;
      max-width: 12rem;
    }

    @media (max-width: 575px) {
      margin-right: 1rem;
      max-width: 6rem;
    }
  }
}

.common-tabs {
  .nav {
    margin-bottom: 1rem;
  }

  .nav-item {
    &:not(:last-child) {
      margin-right: 1.5rem;
    }

    .nav-link {
      padding: 0;
      padding-bottom: 0.5rem;
      font-size: 1.6rem;
      color: $textColorLight2;
      border-bottom: 0.1rem solid transparent;

      @media (max-width: 767px) {
        font-size: 1.3rem;
      }

      &.active {
        color: $black;
        border-color: $orange;
      }
    }
  }
}
