@import "../../../assets/theme/_var.scss";

.home-page {
  min-height: 100vh;
  background-color: $white;
  background-image: url(../../../assets/images/main_bg.jpg);
  background-repeat: no-repeat;
  background-size: 100%;

  @media (max-width: 1679px) {
    background-size: cover;
  }

  .container {
    max-width: 1615px;

    @media (max-width: 1679px) {
      max-width: 1415px;
      padding: 0 1.5rem;
    }
  }
}
