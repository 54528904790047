@import "../../../../../assets/theme/_var.scss";

.stacks {
  padding-bottom: 10rem;

  @media (max-width: 767px) {
    padding-bottom: 5rem;
    pointer-events: none;
  }

  @media (max-wdith: 575px) {
    padding-bottom: 2rem;
  }
}

.stack-sec {
  width: fit-content;
  margin-inline: auto;
  overflow: hidden;
  position: relative;
}

.all_boxes {
  span {
    position: absolute;
    border-radius: 2.5rem;
    cursor: move;
    user-select: none;
    pointer-events: none;
    display: flex;
    padding: 0 3.6rem;
    height: 5rem;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    font-size: 2rem;
    font-weight: 600;
    font-family: $baseFont2;
    color: #010101;

    @media (max-width: 1439px) {
      font-size: 1.8rem;
      padding: 0 3rem;
      height: 4.6rem;
    }

    @media (max-width: 1199px) {
      font-size: 1.6rem;
      padding: 0 2.6rem;
      height: 4.2rem;
    }

    @media (max-width: 767px) {
      height: 3.4rem;
      padding: 0 1.4rem;
      font-size: 1.2rem;
    }
  }
}

#areaToRender {
  width: fit-content;
  margin-inline: auto;
}