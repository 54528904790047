@import "../../../../../../assets/theme/_var.scss";

.news-card {
  display: block;
  border-radius: 1.2rem;
  box-shadow: 0px -3px 30px 0px rgba(0, 0, 0, 0.05);
  height: 100%;
  background-color: $white;

  &__img {
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      padding-bottom: 21rem;

      @media (max-width: 1679px) {
        padding-bottom: 18rem;
      }
      //   @media (max-width: 1368px) {
      //     padding-bottom: 15rem;
      //   }
    }
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      border-radius: 1rem 1rem 0 0;
    }
  }

  &__content {
    padding: 3rem 1.5rem;
    color: $baseColor;

    @media (max-width: 1679px) {
      padding: 2rem 1.5rem;
    }
    @media (max-width: 767px) {
      padding: 1.5rem 1rem;
    }

    h6 {
      margin-bottom: 0.5rem;
      font-weight: 600;
    }
    p,
    small {
      font-size: 1.4rem;
      color: rgba($baseColor, 0.7);

      @media (max-width: 1368px) {
        font-size: 1.2rem;
      }
    }

    small {
      display: inline-block;
      margin-bottom: 0.5rem;
    }
  }
}
