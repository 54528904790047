@import "../../../../assets/theme/_var.scss";

.Platform_Card {
  background: radial-gradient(
    70% 190% at 50% -90%,
    #f27142 -230%,
    #d9d9d900 100%
  );
  box-shadow: 0px 20px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3.2rem;
  padding: 6rem 4rem;
  flex-basis: 34rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 991px) {
    padding: 5rem 3rem;
  }

  @media (max-width: 767px) {
    padding: 3rem;
    flex-basis: 50%;
  }
  @media (max-width: 575px) {
    border-radius: 2.2rem;
    padding: 2rem;
    flex-basis: 100%;

    svg {
      max-width: 3rem;
      height: auto;
    }
  }

  h5 {
    font-weight: 500;
    color: $baseColor2;
    margin: 0;
    margin-top: 2rem;

    @media (max-width: 767px) {
      font-size: 1.6rem;
    }
    // @media (max-width: 575px) {
    //   margin-top: 1rem;
    //   font-size: 1rem;
    // }
  }

  &.plat-base1 {
    flex-grow: 1;
    flex-basis: unset;
    margin-left: 3rem;
    background: radial-gradient(
      70% 190% at 50% -90%,
      #3cff99 -230%,
      #d9d9d900 100%
    );

    @media (max-width: 767px) {
      margin-left: 1.5rem;
    }
  }

  &.plat-base2 {
    flex-grow: 1;
    flex-basis: unset;
    margin-right: 3rem;
    background: radial-gradient(
      70% 190% at 50% -90%,
      #8160b3 -230%,
      #d9d9d900 100%
    );

    @media (max-width: 767px) {
      margin-right: 1.5rem;
    }
  }

  &.plat-base3 {
    flex-basis: 29.8rem;
    background: radial-gradient(
      70% 190% at 50% -90%,
      #1fe4ff -230%,
      #d9d9d900 100%
    );
  }
}

@media (max-width: 767px) {
  .Platform_Card {
    &.plat-base1 {
      flex-basis: 50%;
    }

    &.plat-base2 {
      flex-basis: 50%;
    }

    &.plat-base3 {
      flex-basis: 50%;
    }
  }
}

@media (max-width: 575px) {
  .Platform_Card {
    &.plat-base1 {
      flex-basis: 100%;
      margin-left: 0;
    }

    &.plat-base2 {
      flex-basis: 100%;
      margin-right: 0;
    }

    &.plat-base3 {
      flex-basis: 100%;
    }
  }
}
