@import "../../../../assets/theme/_var.scss";

.social-links {
  display: flex;
  li {
    line-height: 1;
    &:not(:last-child) {
      margin-right: 1.2rem;

      @media (max-width: 767px) {
        margin-right: 0.7rem;
      }
    }
    a {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
      transition: all linear 0.2s;

      &:hover {
        .social-links-icon {
          transition: all linear 0.2s;
          transform: translateY(-5px);
        }
      }
    }
  }
  &-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    background-color: rgba(33, 33, 33, 0.5);
    border-radius: 0.8rem;
    transition: all linear 0.2s;

    @media (max-width: 767px) {
      width: 3rem;
      height: 3rem;
    }
  }
}
