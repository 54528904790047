@import "../../../../../assets/theme/_var.scss";

section.FutureFinance {
  padding: 7.5rem 0;

  @media (max-width: 991px) {
    padding: 5rem 0;
  }

  @media (max-width: 575px) {
    padding: 3rem 0;
  }
  .Finance_wrap {
    border-radius: 1.2rem;
    background: linear-gradient(
      90deg,
      #0050a5 -30.87%,
      #351a9c 11.68%,
      #b7254c 65.01%,
      #ff933e 120.02%
    );
    text-align: center;
    padding: 4.3rem 2rem 9.7rem;
    color: $white;

    @media (max-width: 991px) {
      padding: 3.5rem 2rem 5rem;
    }

    @media (max-width: 575px) {
      padding: 2.5rem;
    }

    svg {
      height: 10rem;
      width: 10rem;
      border: 5rem;

      @media (max-width: 991px) {
        height: 7.5rem;
        width: 7.5rem;
      }

      @media (max-width: 575px) {
        height: 6rem;
        width: 6rem;
      }
    }

    h3 {
      font-weight: 500;
      margin: 3.3rem 0 3rem;
      color: $white;

      @media (max-width: 991px) {
        margin: 2.5rem 0;
      }

      @media (max-width: 575px) {
        margin: 1.5rem 0 1rem;
        font-size: 1.3rem;
      }
    }

    h2 {
      margin: 0;
      font-weight: 600;
      color: $white;
    }
  }
}
