@import "../../../../assets/theme/_var.scss";

.commonBtn {
  color: $white;
  font-weight: 600;
  padding: 0.5rem 2.5rem;
  font-size: 1.6rem;
  height: 6rem;
  line-height: 1;
  letter-spacing: 0.48px;
  transition: all ease 0.3s;
  background: $baseGradient;
  border-radius: 3.5rem;
  position: relative;
  text-transform: capitalize;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: 1679px) {
    font-size: 1.4rem;
    height: 5rem;
  }

  @media (max-width: 767px) {
    font-size: 1.3rem;
    height: 4rem;
    padding: 0.5rem 1.5rem;
  }

  &:after {
    content: "";
    position: absolute;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    background: linear-gradient(to bottom,
        rgba(229, 172, 142, 0),
        rgba(255, 255, 255, 0.3) 50%,
        rgba(229, 172, 142, 0));
    transform: rotateZ(60deg) translate(-5em, 7.5em);
  }

  &:hover,
  &:focus {
    box-shadow: 0px -3px 30px 0px rgba(0, 0, 0, 0.1);

    &::after,
    &::after {
      animation: sheen 1s forwards;

      @keyframes sheen {
        100% {
          transform: rotateZ(60deg) translate(1em, -9em);
        }
      }
    }
  }

  .btn-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    border-radius: 2.5rem;
    background-color: #ff8a45;

    @media (max-width: 1679px) {
      width: 4rem;
      height: 4rem;
    }

    @media (max-width: 767px) {
      width: 3rem;
      height: 3rem;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.icon-btns {
    justify-content: space-between;

    .btn-icon {
      margin-left: 1rem;
      opacity: 0;
      margin-left: -4.7rem;
      z-index: -1;
      transition: all ease 0.3s;

      @media (max-width: 767px) {
        margin-left: -3rem;
      }
    }

    &:hover {
      padding-right: 0.5rem;

      .btn-icon {
        z-index: 1;
        visibility: visible;
        margin-left: 2rem;
        opacity: 1;

        @media (max-width: 767px) {
          margin-left: 1rem;
        }
      }
    }
  }
}