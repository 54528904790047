@import "../../../../../assets/theme/_var.scss";

.news-sec {
  padding: 5rem 0;
  position: relative;
  overflow: hidden;

  @media (max-width: 767px) {
    padding: 3rem 0;
  }

  .container {
    position: relative;
    z-index: 1;
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 95, 0, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    width: 55rem;
    height: 50rem;
  }

  &::after {
    top: 0;
    left: -11rem;
  }
  &::before {
    bottom: 0rem;
    right: -5rem;

    @media (max-width: 767px) {
      content: none;
    }
  }

  h2 {
    margin-bottom: 5rem;

    @media (max-width: 991px) {
      margin-bottom: 3rem;
    }

    @media (max-width: 575px) {
      margin-bottom: 1rem;
    }
  }

  .slick-slider {
    .slick-slide {
      padding: 0 1rem;

      @media (max-width: 1368px) {
        padding: 0 0.75rem;
      }
      @media (max-width: 767px) {
        padding: 0 0.5rem;
      }
    }
    .slick-track {
      padding: 2rem 0 3.5rem;

      @media (max-width: 991px) {
        padding: 2rem 0 2rem;
      }
    }

    .slick-arrow {
      z-index: 1;
      &.slick-prev {
        @media (max-width: 767px) {
          left: -0.5rem;
        }
      }
      &.slick-next {
        @media (max-width: 767px) {
          right: -0.5rem;
        }
      }
    }
  }
}
