@import "../../../../../assets/theme/_var.scss";

section.TradeCrypfi {
  padding: 3rem 0 7.5rem;

  @media (max-width: 767px) {
    padding: 2.5rem 0;
  }

  .Cardbg-clr {
    border-radius: 1.2rem;
    background: $baseColor;
    box-shadow: 0px -3px 30px 0px rgba(0, 0, 0, 0.1);
    height: 100%;
  }
}

.Trade_heading {
  h2 {
    font-weight: 600;
    margin-bottom: 5rem;
    color: $baseColor2;

    @media (max-width: 767px) {
      margin-bottom: 3rem;
    }
  }
}
