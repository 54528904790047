@import "../../../../../assets/theme/_var.scss";

section.CryptoPlatform {
  padding: 7.5rem 0;

  @media (max-width: 767px) {
    padding: 4rem 0;
  }

  .Platform-wrap {
    max-width: 83.7rem;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    align-items: flex-end;

    @media (max-width: 575px) {
      gap: 1.5rem;
      flex-direction: column;
      margin-bottom: 1.5rem;
    }
  }

  .Capital-wrap {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 10rem;
    max-width: 98.9rem;

    @media (max-width: 991px) {
      margin-bottom: 5rem;
    }

    @media (max-width: 575px) {
      flex-direction: column;
      gap: 1.5rem;
    }
  }

  .Product-text {
    h4 {
      font-weight: 500;
      margin: 0;
      color: $baseColor2;
      margin-bottom: 3.5rem;
    }
  }
}

@media screen and (max-width: 767px) {
  section.CryptoPlatform {
    .Platform-wrap {
      align-items: unset;
    }
  }
}
