@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap"); // lexend font

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); // monteserrat font

$baseFont: "Lexend", sans-serif;
$baseFont2: "Montserrat", sans-serif;

$orange: #ff5f00;
$orange2: #ff6102;
$black: #000000;
$white: #ffffff;

$baseColor: #17181b;
$baseColor2: #141416;
$baseColor3: #2a2a2a;
$baseColor4: #2f2f2f;
$baseColor5: #0d0d0f;
$baseColor6: #0d0d0f;
$baseColor7: #19191d;
$baseColor8: #232323;
$baseColor9: #373737;
$baseColor10: #1f1f1f;
$baseColor11: #1a1a1a;
$baseColor12: #282a2f;
$primaryColor: #daa71e;
$blackColor: #17181b;
$borderColor: #23262f;
$borderColor2: #383d4a;
$borderColor3: #42444a;
$borderColor4: #d6d6d6;
$borderColor5: #555b6c;
$borderColor6: #999999;

$textColorLight: #bbbbbb;
$textColorLight2: #a1a1a2;
$textColorLight3: #4f4f4f;
$textColorDark: #454545;

$textRed: #ed2525;
$textRed2: #cf3200;
$textGreen: #00d42f;
$textGreen2: #5fa811;
$white2: #f2f2f2;
$white3: #e0dfdf;

$fadeGreen: rgba(#192d2a, 0.25);
$fadeRed: rgba(#3a252d, 0.25);

$textYellow: #e4b741;
$textYellow2: #e3b440;
$baseGradient: linear-gradient(90deg, #ff5f00 0%, #ff4d00 100%);
$baseGradient2: linear-gradient(180deg, #ffc905 0%, #f1a02b 100%);
