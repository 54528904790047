@import "../../../../assets/theme/_var.scss";

.Common_Card {
  border-radius: 1.2rem;
  background: radial-gradient(
    130% 129.99% at 51.39% 3.92%,
    rgba(255, 112, 116, 0.54) 0%,
    rgba(51, 34, 37, 0) 100%
  );
  box-shadow: 0px -3px 30px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 2.6rem 3.5rem 6.3rem;
  height: 100%;

  @media (max-width: 991px) {
    padding: 2rem 3rem 5rem;
  }

  @media (max-width: 575px) {
    padding: 2rem 3rem;
  }

  &.Cardbg-gren {
    background: radial-gradient(
      130% 129.99% at 51.39% 3.92%,
      rgba(116, 215, 211, 0.54) 0%,
      rgba(51, 34, 37, 0) 100%
    );
  }

  &.Cardbg-lightgren {
    background: radial-gradient(
      130% 129.99% at 51.39% 3.92%,
      rgba(97, 204, 101, 0.54) 0%,
      rgba(51, 34, 37, 0) 100%
    );
  }

  &.Cardbg-pink {
    background: radial-gradient(
      130% 129.99% at 51.39% 3.92%,
      rgba(209, 90, 124, 0.54) 0%,
      rgba(51, 34, 37, 0) 100%
    );
  }

  &.Cardbg-yellow {
    background: radial-gradient(
      130% 129.99% at 51.39% 3.92%,
      rgba(255, 211, 66, 0.54) 0%,
      rgba(51, 34, 37, 0) 100%
    );
  }

  &.Cardbg-purple {
    background: radial-gradient(
      130% 129.99% at 51.39% 3.92%,
      rgba(196, 112, 255, 0.54) 0%,
      rgba(51, 34, 37, 0) 100%
    );
  }

  &.Cardbg-blue {
    background: radial-gradient(
      130% 129.99% at 51.39% 3.92%,
      rgba(98, 148, 235, 0.54) 0%,
      rgba(51, 34, 37, 0) 100%
    );
  }

  h5 {
    color: $white;
    font-weight: 600;
    margin: 0;

    @media (max-width: 575px) {
      font-size: 1.2rem;
    }
  }

  .Cardimg-wrap {
    min-height: 13rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 991px) {
      min-height: 10rem;
    }
    @media (max-width: 575px) {
      min-height: 6rem;
    }

    img {
      @media (max-width: 991px) {
        max-width: 8rem;
      }

      @media (max-width: 575px) {
        max-width: 5rem;
      }
      &.Zero-gas {
        margin-bottom: -3rem;

        @media (max-width: 991px) {
          margin-bottom: -1rem;
        }
      }
    }
  }

  // &.revenue {
  //     background: radial-gradient(70% 190% at 50% -90%, #F27142 -230%, #D9D9D900 100%);
  //     box-shadow: 0px 20px 15px 0px rgba(0, 0, 0, 0.1);
  //     border-radius: 32.802px;

  //     h5 {
  //         color: #141416;
  //         font-weight: 500;
  //     }

  //     &.grn-colr {
  //         background: radial-gradient(70% 190% at 50% -90%, #3CFF99 -230%, #D9D9D900 100%);
  //     }

  //     &.prpl-colr {
  //         background: radial-gradient(70% 190% at 50% -90%, #8160B3 -230%, #D9D9D900 100%);
  //     }

  //     &.skyblue-colr {
  //         background: radial-gradient(70% 190% at 50% -90%, #1FE4FF -230%, #D9D9D900 100%);
  //     }
  // }
}
