@import "~bootstrap/scss/bootstrap";
@import "./assets/theme/_var.scss";

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: $baseFont;
  font-size: 1.6rem;
  color: $baseColor2;
  background-color: $white;
}

ul {
  padding-left: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

img,
svg,
video {
  max-width: 100%;
}

canvas {
  pointer-events: all !important;
  touch-action: auto !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

h1,
.h1 {
  font-size: 7rem;
  font-weight: 700;

  @media (max-width: 1679px) {
    font-size: 6rem;
  }

  @media (max-width: 1365px) {
    font-size: 4.5rem;
  }

  @media (max-width: 991px) {
    font-size: 4rem;
  }

  @media (max-width: 575px) {
    font-size: 3rem;
  }
}

h2,
.h2 {
  font-size: 5rem;

  @media (max-width: 1679px) {
    font-size: 4rem;
  }

  @media (max-width: 1365px) {
    font-size: 3.6rem;
  }

  @media (max-width: 991px) {
    font-size: 3rem;
  }

  @media (max-width: 575px) {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: 3.6rem;

  @media (max-width: 1679px) {
    font-size: 3rem;
  }

  @media (max-width: 1365px) {
    font-size: 2.6rem;
  }

  @media (max-width: 991px) {
    font-size: 2.2rem;
  }
}

h4,
.h4 {
  font-size: 2.8rem;

  @media (max-width: 1679px) {
    font-size: 2.4rem;
  }

  @media (max-width: 1365px) {
    font-size: 2.2rem;
  }

  @media (max-width: 991px) {
    font-size: 2rem;
  }

  @media (max-width: 575px) {
    font-size: 1.8rem;
  }
}

h5,
.h5 {
  font-size: 2.4rem;

  @media (max-width: 1679px) {
    font-size: 2rem;
  }

  @media (max-width: 1365px) {
    font-size: 1.8rem;
  }

  @media (max-width: 991px) {
    font-size: 1.6rem;
  }
}

h6,
.h6 {
  font-size: 2rem;

  @media (max-width: 1679px) {
    font-size: 1.6rem;
  }

  @media (max-width: 1365px) {
    font-size: 1.4rem;
  }
}

p,
.p {
  font-size: 1.6rem;

  @media (max-width: 1679px) {
    font-size: 1.4rem;
  }

  @media (max-width: 991px) {
    font-size: 1.3rem;
  }
}

.text-orange {
  color: $orange !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

// slick slider style

.slick-slider {
  // arrow style

  .slick-track {
    display: flex;
    height: 100%;
  }

  .slick-slide {
    min-height: 0;
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    height: auto;

    > div,
    > div > div {
      height: 100%;
    }
  }

  .slick-arrow {
    opacity: 1;
    &.slick-disabled {
      opacity: 0.5;
    }
    &.slick-prev,
    &.slick-next {
      top: 60%;
    }
    &.slick-prev {
      left: -1rem;
    }
    &.slick-next {
      right: -1rem;
    }
    &::before {
      content: none;
    }
  }

  // dots style
  .slick-dots {
    bottom: -1.5rem;
    li {
      width: 10px;
      height: 10px;
      margin: 0 0.3rem;
      button {
        padding: 0;
        width: 10px;
        height: 10px;
        background: rgba(111, 114, 116, 0.3);
        border-radius: 50%;
        &::before {
          content: none;
        }
      }

      &.slick-active {
        button {
          background: $orange;
        }
      }
    }
  }
}
